import React from "react";

import SEO from "../components/seo";

const NotFoundPage = () => (
  <>
    {/* <SEO title="404: Not found" keywords={[`great`, `british`, `dogs`]} /> */}
    <SEO
      title="greatbritishdogs.co.uk | 404: Not found"
      keywords={[`great`, `british`, `dogs`]}
    />
    <div className="not-found">
      <h1>SORRY</h1>
      <p>We couldn't find that page</p>
    </div>
  </>
);

export default NotFoundPage;
